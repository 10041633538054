





































.device-transformer-factor {
  padding: 10px;
  margin-top: 10px;
  border-top: 1px dashed #14344d;
}
.device-transformer-factor .title {
  font-weight: bold;
}
.device-transformer-factor .middle {
  display: flex;
  align-items: center;
}
